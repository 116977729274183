import { Base } from '@studiometa/js-toolkit';
import isDev from '../utils/is-dev';

/**
 * Homepage class.
 */
class Index extends Base {
  /**
   * App config.
   * @return {Object}
   */
  get config() {
    return {
      name: 'Index',
      log: isDev(),
    };
  }
}

const index = new Index(document.documentElement);
export default index;


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
