/**
 * Test if we are on a dev environment.
 * @return {Boolean}
 */
export default () =>
  !['couteaux-ceccaldi.com', 'www.couteaux-ceccaldi.com'].includes(window.location.hostname);


if (module.hot) {module.hot.accept(function(err) {
if (err) {
console.error(err);
}
});
}
